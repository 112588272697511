import moment from "moment"
import { mapGetters, mapMutations } from "vuex";
import { convert } from "html-to-text";
import PrinterService from "@/services/printer.service";
import { htmlToText } from "@/includes/lib/html-to-text";
import { Printd } from "printd";
const printerService = new PrinterService();

export default {
    data() {
        return {
            settings: [],
            customers: [],
            currency: null,
            header: "",
            footer: "",
            showTax: true,
        };
    },
    created() {
        let { settings, customers } = this.apiData;
        this.customers = customers;
        this.showTax = JSON.parse(localStorage.getItem('showTax'));
        if (Object.keys(settings).length) {
            this.settings = settings;
            this.currency = settings.organization.currency;
            this.header = settings.header;
            this.footer = settings.footer;
        }
    },
    computed: {
        ...mapGetters({
            apiData: "getApiData",
        })
    },
    methods: {
        ...mapMutations(["toggleShowReceipt"]),
        dateFormat(date, format = "DD/MM/YYYY") {
            if (moment(date).isValid()) {
                return moment(date).format(format);
            }
            return "";
        },
        numberFormat(number, splitBy = ",") {
            if (number == null) {
                return "0.00";
            }
            let splitedNumber = String(number).split(splitBy);
            let fixedDicimalNumber = `0.${splitedNumber[1]}`;
            let dicimalNumber = Number(fixedDicimalNumber).toFixed(2);
            let numberExcludDecimal = String(splitedNumber[0]);
            if (dicimalNumber == '1.00') {
                numberExcludDecimal = String(Number(numberExcludDecimal) + 1);
            }
            numberExcludDecimal = String(numberExcludDecimal).replace(/(.)(?=(\d{3})+$)/g, '$1 ');
            let numberAfterComma = String(dicimalNumber).split('.')[1] || '00';
            if (numberExcludDecimal == undefined) {
                return dicimalNumber;
            }
            return `${numberExcludDecimal},${numberAfterComma}`;
        },
        checkoutMethodName(id) {
            let match = _.find(
                this.settings.store.payment_methods,
                (method) => method.id === id
            );
            if (match) {
                return match.title;
            }
            return 12;
        },
        numFormat(number) {
            return this.$filters.numFormat(number);
        },
        linePriceIncltax(o) {
            return this.numberFormat((o.price * (1 - o.discount / 100) * (100 + o.tax)) / 100, ".");
        },
        singleLineDiscountValue(line) {
            let discount = (line.price * (100 + line.tax) / 100) * line.discount / 100;
            return this.numFormat(discount);
        },
        getLineTotal(o) {
            return (o.price * (1 - o.discount / 100) * o.quantity * (100 + o.tax)) / 100;
        },
        linesQuantity(lines) {
            let quantity = 0;
            _.each(lines, (o) => {
                quantity += o.quantity;
            });
            return quantity;
        },
        getClientName(id) {
            let match = _.find(this.customers, (o) => o.id === id);
            if (match) {
                return match.name;
            }
        },

        printReceiptProtocol(selectedReceipt, redirectTo = null) {
            let transactionsContent = printerService.paymentMethodsTemplate();
            let transactionscompiled = _.template(transactionsContent);
            let transactions = selectedReceipt.transactions.map((t) => {
                return {
                    ...t,
                    paymentMethodName: this.checkoutMethodName(t.payment_method)
                }
            });
            let transactionsHtml = transactionscompiled({
                transactions: transactions
            });
            const transactionsHtmlToText = convert(transactionsHtml, {
                wordwrap: false
            });
            let htmlContent = printerService.template1();
            let compiled = _.template(htmlContent);
            let html = compiled({ order: selectedReceipt });
            const options = { tables: ["#invoice", ".address"] };
            const body = htmlToText(html, options);
            const headerTextFormat = convert(this.header, {
                wordwrap: false,
            });
            const footerTextFormat = convert(this.footer, {
                wordwrap: false,
            });
            printerService
                .getEscposPrinter()
                .then((printer) => {
                    printer.font("a");
                    printer.align("CT");
                    printer.style("B");
                    printer.size(0.02, 0.02);
                    printer.text(headerTextFormat);
                    printer.align("LT");
                    printer.text(body);
                    printer.align("CT");
                    printer.drawLine();
                    printer.align("LT");
                    printer.text(
                        `TTC: ${this.numberFormat(selectedReceipt.total, ".")} ${selectedReceipt.currency
                        }`
                    );
                    if (this.showTax) {
                        printer.text(`TVA: ${this.numFormat(selectedReceipt.total_tax)}`);
                    }
                    if (selectedReceipt.discount_amount > 0) {
                        printer.text(
                            `Remise: (${this.numFormat(
                                selectedReceipt.discount_percent
                            )}%) ${this.numFormat(selectedReceipt.discount_amount, ".")}`
                        );
                    }
                    printer.text(`Articles: ${this.linesQuantity(selectedReceipt.lines)}`);
                    printer.text(transactionsHtmlToText);
                    printer.align("CT");
                    printer.feed();
                    printer.text(footerTextFormat);
                    printer.cut();
                    printer.close();
                    this.$toast.success("reçu imprimé", {
                        position: "top-right",
                        timeout: 5000,
                    });
                    this.$router.push({ name: redirectTo });
                })
                .catch((err) => {
                    this.toggleShowReceipt(true);
                    setTimeout(() => {
                        const d = new Printd();
                        d.print(document.getElementById("print-frame"));
                        const iframe = d.getIFrame();
                        const { contentWindow } = iframe;
                        contentWindow.addEventListener("afterprint", () => {
                            this.$toast.success("reçu imprimé", {
                                position: "top-right",
                                timeout: 5000,
                            });
                            this.toggleShowReceipt(false);
                            this.$router.push({ name: redirectTo });
                        });
                    }, 1000);
                });
        }
    },

}