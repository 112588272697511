import EscposWebUSB from 'escpos-webusb';
import escpos from 'escpos';

class PrinterService {

    //TODO give access to usb first.
    async usbRequestDevice() {
        const printerConfig = JSON.parse(localStorage.getItem('printerConfig'));
        if (!printerConfig) {
            return this.getEscposPrinter();
        } else {
            const printerFilters = [{ classCode: 0x07 }];
            const device = await navigator.usb.requestDevice({ filters: printerFilters }).catch(() => null);
            if (device) {
                const protocol = device.manufacturerName && device.manufacturerName.toLocaleUpperCase().includes('EPSON') ? PrinterProtocol.ESCPOS : PrinterProtocol.ZPL;
                const printer = {
                    serialNumber: device.serialNumber,
                    protocol,
                    type: 'USB'
                }
                localStorage.setItem('serialNumber', JSON.stringify(printer));
            }
        }
    }

    async getEscposPrinter() {

        let printerConfig = JSON.parse(localStorage.getItem('printerConfig'));
        if (!printerConfig) {
            let key = null;
            let value = null;
            const printerFilters = [{ classCode: 0x07 }];
            const usbDevice = await navigator.usb.requestDevice({ filters: printerFilters }).catch(() => null);
            if (usbDevice) {
                const protocol = usbDevice.manufacturerName && usbDevice.manufacturerName.toLocaleUpperCase().includes('EPSON') ? PrinterProtocol.ESCPOS : PrinterProtocol.ZPL;
                if (usbDevice.serialNumber) {
                    value = usbDevice.serialNumber;
                    key = 'serialNumber';
                } else if (usbDevice.vendorId) {
                    value = usbDevice.vendorId;
                    key = 'vendorId';
                } else if (usbDevice.productId) {
                    value = usbDevice.productId;
                    key = 'productId';
                }

                printerConfig = {
                    key,
                    value,
                    protocol,
                    type: 'USB',
                }
                localStorage.setItem('printerConfig', JSON.stringify(printerConfig));
            };
        }
        let escposAdapter = null;
        if (printerConfig.type === 'USB') {
            const device = (await navigator.usb.getDevices()).find((d) => d[printerConfig.key] == printerConfig.value);
            if (device) {
                escposAdapter = new EscposWebUSB(device);
            } else {
                return Promise.resolve(null);
            }
        } else {
            return Promise.resolve(null);
        }

        if (!escposAdapter) {
            return Promise.resolve(null);
        }
        return new Promise((resolve, reject) =>
            escposAdapter.open((err) => {
                if (err) {
                    reject(err);
                }
                resolve(new escpos.Printer(escposAdapter, { encoding: 'IBM863' }));
            })
        ).catch(err => {
            console.error('Enable to connect device: ', err);
            return null;
        });
    }

    template1() {
        return `<html>

        <head>
            <meta charset="utf-8">
        </head>
        
        <body>
            <style>
                /* h2 {
                    font-size: 3rem;
                } */
            </style>
            <table cellpadding="0" cellspacing="0" border="0">
                <tr>
                    <td>
                        <table id="invoice">
                            <thead>
                                <tr>
                                    <th>Articles</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <% _.forEach(order.lines, function(line) { %>
                                    <tr>
                                        <td>
                                            <%= line.name %>
                                                (<%= line.quantity %>)
                                                <br />
                                                <% if(line.discount> 0){ %>
                                                    remise de: <%= line.discount_percentage %>&#37;: <%= line.discountValue %>
                                                <% } %>
                                        </td>
                                        <td>
                                            <%= line.totalLine %>
                                        </td>
                                    </tr>
                                <% }); %>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        </body>
        
        </html>`;
    }

    paymentMethodsTemplate() {
        return `
        <html>
            <head>
                <meta charset="utf-8">
            </head>
            <body>
                <% _.forEach(transactions, function(t, index) { %>
                    <p><%= t.amount %> par:  <%= t.paymentMethodName %>  </p>
                <% }); %>
            </body>
        </html>
        `;
    }
}

const PrinterType = Object.freeze({
    USB: 'USB',
    BLUETOOTH: 'BLUETOOTH',
    NETWORK: 'NETWORK',
});
const PrinterProtocol = Object.freeze({
    ESCPOS: 'ESCPOS',
    ZPL: 'ZPL',
});

export default PrinterService;